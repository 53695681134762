/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React, { lazy } from 'react';
import { Redirect } from 'react-router-dom';
import ErrorLayout from '../layouts/Error';
import CustomerLayout from '../layouts/Customer';
import CardLayout from '../layouts/Card';
import ShopLayout from '../layouts/Shop';

const routes = [
  {
    path: '/',
    exact: true,
    component: () => <Redirect to="/errors" />
  },
  {
    path: '/customer',
    component: CustomerLayout,
    routes: [
      {
        path: '/customer/qr/:customerId',
        exact: true,
        component: lazy(() => import('../views/CustomerPage/CustomerQR'))
      },
      {
        path: '/customer/create',
        exact: true,
        component: lazy(() => import('../views/CustomerPage/CustomerCreate'))
      },
      {
        path: '/customer/payment',
        exact: true,
        component: lazy(() => import('../views/CustomerPage/CustomerPayment'))
      },
      {
        path: '/customer/payment/:refNumber',
        exact: true,
        component: lazy(() => import('../views/CustomerPage/PaymentSuccess'))
      },
      // {
      //   path: '/customer/fail',
      //   exact: true,
      //   component: lazy(() => import('../views/CustomerPage/CustomerFail')),
      // },
      {
        component: () => <Redirect to="/errors/error-404" />
      }
    ]
  },
  {
    path: '/static-page',
    component: CustomerLayout,
    routes: [
      {
        path: '/static-page/:id',
        exact: true,
        component: lazy(() => import('../views/StaticPage'))
      },
      {
        component: () => <Redirect to="/errors/error-404" />
      }
    ]
  },
  {
    path: '/card',
    component: CustomerLayout,
    routes: [
      {
        path: '/card/mpu-register',
        exact: true,
        component: lazy(() => import('../views/Card/MPUCardRegister'))
      },
      {
        path: '/card/credit-crosssell',
        exact: true,
        title: 'Card',
        component: lazy(() =>
          import('../views/Card/CrossSell/CrossSellRegister')
        )
      },
      {
        path: '/card/bnpl-samsung',
        exact: true,
        title: 'Card',
        component: lazy(() => import('../views/Card/Samsung/Samsung'))
      },
      {
        path: '/card/credit-register',
        exact: true,
        title: 'Card',
        component: lazy(() =>
          import('../views/Card/CreditCard/CreditCardRegister')
        )
      },
      {
        path: '/card/credit-register/:phoneNumber',
        exact: true,
        title: 'Card',
        component: lazy(() =>
          import('../views/Card/CreditCard/CreditCardRegister')
        )
      },
      {
        path: '/card/credit-promotion',
        exact: true,
        title: 'Promotion',
        component: lazy(() => import('../views/Card/CreditCardPromotion'))
      },
      {
        path: '/card/credit-payroll',
        exact: true,
        title: 'Payroll',
        component: lazy(() => import('../views/Card/CardPayRoll'))
      },
      {
        path: '/card/virtual-prepaid',
        exact: true,
        title: 'Card',
        component: lazy(() =>
          import('../views/Card/DigitalPrepaid/DigitalPrepaidRegister')
        )
      },
      // {
      //   path: '/card/digital-prepaid',
      //   exact: true,
      //   title: 'Card',
      //   component: lazy(() =>
      //     import('../views/Card/DigitalPrepaid/DigitalPrepaidRegister')
      //   )
      // },
      {
        path: '/card/virtual-prepaid/payment/:refNumber',
        exact: true,
        component: lazy(() =>
          import('../views/Card/VirtualPrepaid/PaymentSuccess')
        )
      },
      {
        component: () => <Redirect to="/errors/error-404" />
      }
    ]
  },
  {
    path: '/aya-pay',
    component: CardLayout,
    routes: [
      {
        path: '/aya-pay/merchant-onboard',
        exact: true,
        title: 'Merchant',
        component: lazy(() => import('../views/AYAPAY/MerchantOnboard'))
      },
      {
        component: () => <Redirect to="/errors/error-404" />
      }
    ]
  },
  {
    path: '/errors',
    component: ErrorLayout,
    routes: [
      {
        path: '/errors/error-401',
        exact: true,
        component: lazy(() => import('../views/ErrorPage/Error401'))
      },
      {
        path: '/errors/error-404',
        exact: true,
        component: lazy(() => import('../views/ErrorPage/Error404'))
      },
      {
        path: '/errors/error-500',
        exact: true,
        component: lazy(() => import('../views/ErrorPage/Error500'))
      },
      {
        path: '/errors/error-page',
        exact: true,
        component: lazy(() => import('../views/ErrorPage/CustomError'))
      },
      {
        component: () => <Redirect to="/errors/error-404" />
      }
    ]
  },
  {
    path: '/shop',
    component: ShopLayout,
    routes: [
      {
        path: '/shop/home',
        exact: true,
        component: lazy(() => import('../views/ShopPage/ShopHome'))
      },
      {
        path: '/shop/auth',
        exact: true,
        component: lazy(() => import('../views/ShopPage/ShopAuth'))
      },
      {
        path: '/shop/qr',
        exact: true,
        component: lazy(() => import('../views/ShopPage/ShopQR'))
      },
      {
        path: '/shop/application/:id',
        exact: true,
        component: lazy(() => import('../views/ShopPage/ApplicationProceed'))
      },
      {
        path: '/shop/forgot-password',
        exact: true,
        component: lazy(() => import('../views/ShopPage/ResetPassword'))
      },
      {
        component: () => <Redirect to="/errors/error-404" />
      }
    ]
  },
  {
    path: '/appointment',
    component: CardLayout,
    routes: [
      {
        path: '/appointment/golf',
        exact: true,
        title: 'Appointment',
        component: lazy(() => import('../views/GolfAppointment/Appointment'))
      },
      {
        component: () => <Redirect to="/errors/error-404" />
      }
    ]
  }
];

export default routes;
